



























import rService from '@/services/RequestService/RequestServiceSetup';
import IPInfo from '@/components/IPPopup/IPInfo.vue';
import moment from 'moment';

export default {
  name: 'DialogBreach',
  props: {},
  data() {
    return {
      ipSel: '',
      title: 'More information',
    };
  },
  components: {
    IPInfo,
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
  },
  methods: {
    convertUnix(value: number) {
      return moment.unix(value).format('MM/DD/YYYY HH:mm:ss');
    },
    fromM(value: number) {
      return moment.unix(value).fromNow();
    },
    isUnixTimestamp(str: string | number) {
      if (!str || (typeof str !== 'string' && typeof str !== 'number')) return;
      if (typeof str === 'number') str = str.toString();
      const s = str.trim();
      // Check if str is a unix timestamp
      return s.match(/^[0-9]{10}$/);
    },
    isIp(str: string) {
      if (!str || typeof str !== 'string') return;
      // Check if str is an ip address
      return str.match(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/);
    },
    getKeys(json) {
      return Object.keys(json).filter(function (el) {
        return el !== '__v' && el !== '_id';
      });
    },
  },
  computed: {
    infoData() {
      return this.$store.state.infoData;
    },
    popupActive: {
      get() {
        return this.$store.state.infoData !== null;
      },
      set(val) {
        if (!val) {
          this.$store.commit('infoData', null);
        }
      },
    },
  },
};
