



























































































































































































































































import IPInfo from '@/components/IPPopup/IPInfo.vue';
import MoreInfoDialog from '@/components/MoreInfoDialog.vue';
import { getReferences, getRules, Link } from '@/services/constants';
import hashing from '@/services/Hashing';
import rService from '@/services/RequestService/RequestServiceSetup';
import { mapActions, mapGetters } from 'vuex';
import XLSX from 'xlsx';
import alertS from '../services/AlertService/AlertService';

export default {
  name: 'Home',
  components: {
    MoreInfoDialog,
    IPInfo,
    BreachChips: () => import('@/components/BreachComponents/Chip.vue'),
    DehashedDialog: () => import('@/components/CheckAccount/Dehashed.vue'),
    UsesLeft: () => import('@/components/CheckAccount/UsesLeft.vue'),
    DialogAlert: () => import('@/components/CheckAccount/Alert.vue'),
    SingleLeak: () => import('@/components/CheckAccount/SingleLeak.vue'),
    AlertPlans: () => import('@/components/CheckAccount/PlansAlerts.vue'),
    DialogBreach: () => import('@/components/BreachComponents/DialogBreach.vue'),
    PlanData: () => import('@/components/PlanData.vue'),
  },
  data() {
    return {
      disable_settings: true,
      loadItem: '',
      ipSel: '',
      rowsPerPageItems: [10, 20, 30, 50],
      excel: {
        data: null,
        name: '',
      },
      discordError: '',
      telegramError: '',
      sendDiscord: false,
      sendTelegram: false,
      sendEmail: false,
      saveEntry: true,
      sortBy: 'searched',
      sortDesc: true,
      alertPlans: true,
      colorCard: 'primary',
      instantFilter: true,
      propsSearch: '1',
      absolute: true,
      lastSearch: '',
      searchItem: '',
      loadingBreaches: false,
      breachesFound: [],
      breaches: [],
      filtered: [],
      password: '',
      pagination: {},
      sourceTypes: [],
      allItems: [],
      references: this.getReferences(),
      findLine: null,
      safe: null,
      typeSelected: {
        name: String,
        value: String,
      },
      validFormSearch: false,
      disableSearchBtn: true,
      search: '',
      toSearch: '',
      searchDisabled: true,
      searchHolder: this.$vuetify.lang.t('$vuetify.Home.searchHolder'),
      loading: false,
      lastType: '',
      toSearchVal: '',
      allValues: [],
      values: [],
      searchInputValue: '',
      typeSelP: '',
      entrySearch: '',
      wildcard: false,
      hash_types: JSON.parse(JSON.stringify(hashing.hash_types)),
      hash_type: '',
      hash_applied: false,
      backup_search: '',
    };
  },
  methods: {
    async use_my_ip() {
      const endPoint = 'my_ip';
      const res = await rService.get(endPoint);
      if (res.ip) {
        this.toSearch = res.ip;
      }
    },
    scrollTopTable() {
      const tTable = this.$refs.top_table;
      if (tTable)
        this.$vuetify.goTo(tTable, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
    },
    getLink(name: string) {
      return {
        name: name,
        params: {
          id: 0,
          entry: this.toSearch,
          type: this.editType(this.typeSelected),
        },
      };
    },
    async searchDBInstance(query: string, type: string, wildcard: boolean) {
      const postBody = {
        query,
        type,
        wildcard,
      };
      this.loading = true;
      let leaks = await rService.post('user_searches/ex/get_entry', postBody, true);
      leaks = leaks.result;
      console.log('Leaks', leaks);
      this.loading = false;
      this.safe = false;
      this.colorCard = 'unsafe';
      this.loadValues(leaks, false);
    },
    loadSearch() {
      const route = this.$route;
      this.searchDisabled = false;
      if (route.params) {
        const params = route.params;
        if (params.type && params.entry) {
          this.toSearch = params.entry;
          this.typeSelected = params.type;
          if (route.name === 'experimentalSearchEntry') {
            const { entry, type, wildcard } = params;
            this.searchDisabled = true;
            this.searchDBInstance(entry, type, wildcard == '1');
          }
        } else {
          this.typeSelected = params.typeSelected;
          this.toSearch = params.toSearch;
        }
      } else {
        this.typeSelected = '';
        this.toSearch = '';
      }
    },
    async getSettings() {
      if (!this.userInfo.unique_id) return;
      this.disable_settings = true;
      if (!this.isEnabled) {
        this.sendDiscord = false;
        this.sendTelegram = false;
        this.sendEmail = false;
        this.saveEntry = false;
        return;
      }
      const settings = await rService.post(`get_settings`, { table: 'settings_1' }, true, false);
      if (settings) {
        console.log('Settings', settings);
        this.sendDiscord = settings.sendDiscord;
        this.sendTelegram = settings.sendTelegram;
        this.sendEmail = settings.sendEmail;
        this.saveEntry = settings.saveEntry;

        if (this.sendDiscord) {
          this.sendDiscordUpdate();
        }
        if (this.sendTelegram) {
          this.sendTelegramUpdate();
        }
      }
      this.disable_settings = false;
    },
    async saveSettings() {
      if (!this.userInfo.unique_id) return;
      this.disable_settings = true;
      await rService.post(
        `save_settings`,
        {
          table: 'settings_1',
          sendDiscord: this.sendDiscord,
          sendTelegram: this.sendTelegram,
          sendEmail: this.sendEmail,
          saveEntry: this.saveEntry,
        },
        true,
        false
      );
      this.disable_settings = false;
    },
    async checkPlatform(platform: string, prop: string, alert = false) {
      const checkApi = await rService.get(`check_bot_link/${platform}`);
      const userId = checkApi.user_id;
      if (!userId && this[prop]) {
        const route = this.$router.resolve({ name: 'botLinks' });
        if (alert) {
          const text = this.translate('linkRequest');
          const str = text.replace('<platform>', platform);
          alertS.error(`${str}<a target="_blank" href="${route.href}">${route.href}</a>`);
        }
        this[prop] = false;
      } else if (alert) {
        this.saveSettings();
      }
    },
    async sendDiscordUpdate(alert = false, preventSave = false) {
      console.log('Send discord update');
      await this.checkPlatform('discord', 'sendDiscord', alert);
    },
    async sendTelegramUpdate(alert = false) {
      await this.checkPlatform('telegram', 'sendTelegram', alert);
    },
    async sendUpdates() {
      await this.sendTelegramUpdate();
      await this.sendDiscordUpdate();
    },
    applyHash() {
      hashing.applyHash(this);
    },
    enableWildcard() {
      localStorage.wildcard = this.wildcard;
    },
    loadWildcard() {
      if (localStorage && localStorage.wildcard == 'true') {
        this.wildcard = true;
      }
    },
    async loadMoreInfo(item) {
      if (this.userInfo && this.userInfo.platform) {
        this.loadItem = item.id;
        const endpoint = 'snusbase/moreInfo/' + this.userInfo.platform;
        const [term, type, database] = item.moreInfo;
        const postBody = {
          database,
          term,
          type,
        };
        const dataLeak = await rService.post(endpoint, postBody, false, true);
        if (dataLeak && !dataLeak.error) {
          this.$store.commit('infoData', dataLeak);
        }
        this.loadItem = '';
      }
    },
    row_classes(item) {
      if (item.password && item.hash) {
        return 'blue darken-4';
      }
      return;
    },
    showTracking() {
      const isTracking = this.$router.currentRoute.name == 'trackingData';
      if (isTracking) return ' - Tracking';
      else return '';
    },
    exportExcel: function () {
      const dataToExport = this.values.map((el) => {
        return {
          Line: el.line,
          Email: el.email,
          Username: el.username,
          Password: el.password,
          Hash: el.hash,
          Sources: el.sources[0].Name,
          IP: el.lastip,
          Name: el.name,
        };
      });
      const data = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      let filename = `${this.typeSelP}_${this.lastSearch}`;
      if (filename.length > 30 || filename.includes('*')) {
        filename = 'downloadLeaks';
      }
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    rowClick(value) {
      this.$refs.singleLeak.open(value);
    },
    ...mapActions(['setupTimer', 'destroyUserInfo', 'openSnack', 'updateUserInfo', 'setCooldown', 'openLogin']),
    editType(type: string) {
      if (type === 'hash') {
        type = 'hashed_password';
      }
      return type;
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    timeAgo(date) {
      return this.$moment(date).fromNow();
    },
    changedSource() {
      const values = this.sourceTypes;
    },
    processColor(link: Link) {
      if (link.unknown) {
        return 'grey';
      }
      const color = link.leakCheck && !link.hbwPwned ? '#5446F8' : link.hbwPwned && !link.leakCheck ? '#3a9ac4' : link.leakCheck && link.hbwPwned ? 'red lighten-1' : 'black';
      return color;
    },
    processDisabled(link: Link) {
      return link.unknown || (link.leakCheck && !link.hbwPwned);
    },
    processDisabledRef(reference) {
      const val = reference.value;
      const selected = this.typeSelected;
      if (val == 'hibp') {
        const allowed = ['email', 'login'];
        return !allowed.includes(selected);
      }
    },
    getTypes() {
      return [
        {
          name: 'Email',
          value: 'email',
        },
        {
          name: 'Email by keyword',
          value: 'mass',
        },
        {
          name: 'Username',
          value: 'username',
        },
        {
          name: 'IP',
          value: 'lastip',
        },
        {
          name: 'Password',
          value: 'password',
        },
        {
          name: 'Name',
          value: 'name',
        },
        {
          name: 'Hashed Password',
          value: 'hash',
        },
        {
          name: 'Crack Hash',
          value: 'crack_hash',
        },
      ];
    },
    getReferences() {
      return getReferences(this.translate);
    },
    customFilter(value, stringSearch, item) {
      /* Search parameters */
      const { sourceTypes, breachesFound, searchItem } = {
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };

      if (breachesFound && breachesFound.length) {
        const oneBrachF = breachesFound.find((el) => {
          return (
            item.sources.find((elS) => {
              return elS.Name == el;
            }) != undefined
          );
        });
        if (oneBrachF == undefined) return false;
      }
      if (sourceTypes && sourceTypes.length) {
        const itemSources = item.sources.find((el) => {
          const elLinked = el.Linked && el.Linked.unknown ? { unknown: true } : { ...el.Linked };
          return this._.find(sourceTypes, { Linked: elLinked });
        });
        if (itemSources == undefined) {
          return false;
        }
      }
      return true;
    },
    loadBreaches() {
      this.loadingBreaches = true;
      this.breaches = [];
      this.values.forEach((el) => {
        el.sources.forEach((element) => {
          delete element.unknown;
          if (element.Name != 'Unknown') {
            if (this._.find(this.breaches, element) == undefined) this.breaches.push(element);
          }
        });
      });
      this.loadingBreaches = false;
    },
    resetFields(ev) {
      this.values = [];
      this.allValues = [];
      this.breaches = [];
      this.typeSelected = '';
      this.breachesFound = [];
      this.searchInputValue = '';
      this.safe = null;
      this.colorCard = 'primary';
    },
    updateFiltered(items) {
      this.filtered = [];
      if (this.$refs.tableLeaks) this.filtered = this.$refs.tableLeaks.$children[0].filteredItems;
      else this.filtered = items;
    },
    goPricing() {
      const access_token = this.$store.state.userInfo.token;
      if (!access_token) {
        this.openLogin();

        // alertS.error('You have to login first');
      } else {
        this.$router.push({ name: 'pricing' }).catch();
      }
    },
    cleanLastResult() {
      this.sourceTypes = [];
      this.breachesFound = [];
      this.searchItem = '';
    },
    async areYourSure() {
      this.$refs.dialogAlert.open();
    },
    loadValues(leaks, noAlert) {
      console.log('Leaks', leaks);
      const { found, foundWithPass, results } = leaks;
      if (!noAlert) {
        this.$swal.close();
      }
      this.values = results;
      this.allValues = Object.assign(this.values);
      this.loadBreaches();
    },
    async checkBreaches() {
      this.cleanLastResult();
      this.values = [];
      this.breaches = [];
      const postBody = {
        entry: this.toSearch,
        type: this.typeSelected,
        wildcard: this.wildcard,
        sendDiscord: this.sendDiscord,
        sendTelegram: this.sendTelegram,
        sendEmail: this.sendEmail,
        saveEntry: this.saveEntry,
      };
      this.typeSelP = `${this.typeSelected}`;
      this.lastSearch = `${this.toSearch}`;
      this.loading = true;

      alertS.loading(this.translate('checkingForLeaks'));
      const leaks = await rService.post(`snusbase/${this.userInfo.platform}`, postBody, false, true);
      this.setupTimer();
      this.updateUserInfo();
      if (leaks.error) {
        const error = leaks.error;
        if (error == 'No user') {
          leaks.error = 'User session expired..';
          this.destroyUserInfo();
        }
        this.loading = false;
        return alertS.error(leaks.error);
      }
      if (leaks.success) {
        alertS.success(this.translate('success'));
        this.values = [];
        this.allValues = [];
        this.safe = true;
        this.colorCard = 'safe';
      } else {
        this.safe = false;
        this.colorCard = 'unsafe';
        this.loadValues(leaks, false);
      }
      this.loading = false;
      this.setCooldown({ status: true, resetTimeLeft: true });
    },
    cleanSearch() {
      this.hash_applied = false;
      this.backup_search = '';
      // this.toSearch = '';
      const searchField: any = document.querySelector('.searchFieldCol .mdi-close');
      if (searchField) {
        try {
          searchField.click();
        } catch (e) {
          console.error(e);
        }
      }
    },
    searchNow() {
      this.values = this.allValues.filter((el) => {
        return this.customFilter(null, null, el);
      });
    },
    checkType(type) {
      if (type != this.lastType) {
        this.cleanSearch();
        const placeHolderType = this.placeHolderLink[type];
        if (!placeHolderType) {
          this.searchHolder = this.$vuetify.lang.t('$vuetify.Home.searchHolder');
          this.searchDisabled = true;
          this.disableSearchBtn = true;
        } else {
          this.searchHolder = `${this.$vuetify.lang.t('$vuetify.Home.enter')} ${placeHolderType}`;
          this.searchDisabled = false;
          this.disableSearchBtn = false;
        }
        this.lastType = type;
      }
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    getRules() {
      return getRules(this.translate, true);
    },
    download(sources) {
      let text = '';
      let title = '';
      if (sources) {
        title = `${this.lastSearch} with sources ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return `${el.line} [${el.sources
              .map((source) => {
                return source.Name;
              })
              .join(',')}]`;
          })
          .join('\n');
      } else {
        title = `${this.lastSearch} ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return el.line;
          })
          .join('\n');
      }
      const FileSaver = require('file-saver');
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${title}.txt`);
    },
  },
  computed: {
    isEnabled() {
      return (this.userInfo && ['pro', 'plus'].includes(this.userInfo.plan)) || this.userInfo.unlock_role;
    },
    isSearch() {
      return this.$route.name === 'experimentalSearchEntry';
    },
    dehashedDisabled() {
      return !this.validFormSearch || this.disableSearchBtn || this.limitReached || this.activeCooldown;
    },
    isUserResult() {
      const userKeys = ['login', 'pass_login'];
      return userKeys.includes(this.typeSelP);
    },
    maxHeightSourcesMenu() {
      if (this.windowWidth < 768) return 150;
      return 304;
    },
    types() {
      try {
        const allowedTypes = this.timerInfo.allowedTypes;
        return this.getTypes();
      } catch (e) {
        return this.getTypes().map(function (el) {
          el.disabled = true;
          return el;
        });
      }
    },
    headers() {
      const toReturn = [
        { text: 'Username', value: 'username', width: '150px' },
        { text: 'Email', value: 'email' },
        { text: 'Password', value: 'password' },
        { text: 'Hash', value: 'hash', width: '150px' },
        { text: 'IP', value: 'lastip' },
        { text: 'Name', value: 'name' },
        { text: 'Source', value: 'sources' },
        { text: 'More Info', value: 'hasMoreInfo' },
      ];
      return toReturn;
    },
    propsSearchItem() {
      return {
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };
    },
    rules: function () {
      const { generalRules, ruleTypes } = this.getRules();
      const extra = ruleTypes[this.typeSelected];
      if (extra) {
        return [...generalRules, ...extra];
      } else {
        return [];
      }
    },
    placeHolderLink: function () {
      const t = this.translate;
      return {
        username: 'Username',
        email: 'Email',
        mass: 'Email by keyword',
        lastip: 'IP',
        password: 'Password',
        name: 'Name',
        hash: 'Hash',
        crack_hash: 'Crack Hash',
      };
    },
    ...mapGetters(['activeCooldown', 'userInfo', 'loggedIn', 'timerInfo', 'limitReached', 'limitReachedAbs']),
    ...mapGetters({
      connected: 'socket/connected',
    }),
    overlay() {
      return !(this.userInfo.plan && this.userInfo.plan != 'unverified');
    },
  },
  mounted() {
    this.getSettings();
    this.loadSearch();
    this.$socket.emit('start_connection', true);
  },
  beforeMount() {
    this.loadWildcard();
  },
  beforeDestroy() {
    this.$store.commit('updateSafe', null);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'updateCheckInput') {
        this.typeSelected = state.toCheck.type;
        this.toSearch = state.toCheck.info;
      }
    });
  },
  watch: {
    '$route.name'(newVal) {
      this.resetFields();
    },
    'userInfo.unique_id'(newVal) {
      const router = this.$route;
      this.getSettings();
      if (newVal) this.sendUpdates();
    },
    safe(newVal) {
      this.$store.commit('updateSafe', this.safe);
    },
    propsSearchItem: {
      handler(val) {
        if (this.instantFilter) this.searchNow();
      },
      deep: true,
    },
  },
};
